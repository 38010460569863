<!--
 * @Descripttion: 说明
 * @Author: liuzhixiang
 * @Date: 2021-07-12 18:54:18
 * @LastEditors: liuzhixiang
 * @LastEditTime: 2021-07-14 16:13:59
-->
<template>
  <div class="my">
    <div class="my_top">
      <!-- <div class="title">我的</div> -->
      <div class="user">
        <template v-if="user.HeadImg">
          <img
            v-if="user.HeadImg.indexOf('https') == '-1'"
            :src="GLOBAL.hostUrl + '/' + user.HeadImg"
            alt=""
          />
          <img
            v-else-if="user.HeadImg.indexOf('https') != '-1'"
            :src="user.HeadImg"
            alt=""
          />
          <img v-else src="../../assets/images/tx.png" alt="" />
        </template>
        {{ user.NickName }}
      </div>
    </div>
    <div class="my_cont">
      <!-- 优惠券 积分 普利点-->
      <div class="my_j_j_d">
        <div>
          <router-link to="/shop/user/Discount">
            <span>{{ user.ShopCouponCount ? user.ShopCouponCount : 0 }}</span>
            <span>优惠券</span>
          </router-link>
        </div>
        <div>
          <router-link to="/shop/pointsmall/detail">
            <span>{{ user.Point ? user.Point : 0 }}</span>
            <span>我的积分</span>
          </router-link>
        </div>
        <div>
          <router-link to="">
            <span>{{ user.ECash ? user.ECash : 0 }}</span>
            <span>普利点</span>
          </router-link>
        </div>
      </div>

      <!-- 订单 -->
      <div class="order">
        <van-cell-group>
          <van-cell is-link to="/shop/orders/index">
            <template #title>
              <div class="cell_title">全部订单</div>
            </template>
            <template #default>
              <div class="cell_right">查看全部</div>
            </template>
          </van-cell>
        </van-cell-group>
        <div class="ods">
          <div v-for="o in ods" :key="o.value" @click="toOrder(o.value)">
            <img :src="o.img" alt="" />
            <p>{{ o.lable }}</p>
          </div>
        </div>
      </div>

      <div style="height: 0.625rem; background-color: #f5f5f5"></div>
      <!-- 列表 -->
      <van-cell-group class="cells">
        <van-cell v-for="p in cells" :key="p.value" @click="link(p)">
          <template #title>
            <div class="cell_title">
              <img :src="p.img" alt="" />{{ p.lable }}
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { queryStudentInfoById } from "@/api/bbs";
export default {
  data() {
    return {
      cls: [
        {
          value: "12",
          lable: "优惠券",
          url: "/shop/user/Discount",
        },
        {
          value: "3968",
          lable: "我的积分",
          url: "",
        },
        {
          value: "888",
          lable: "普利点",
          url: "",
        },
      ],
      ods: [
        {
          img: require("../../assets/ShopImg/icon_dfk.svg"),
          value: "1",
          lable: "待付款",
        },
        {
          img: require("../../assets/ShopImg/icon_dff.svg"),
          value: "2",
          lable: "待发货",
        },
        {
          img: require("../../assets/ShopImg/icon_dsf.svg"),
          value: "3",
          lable: "待收货",
        },
        {
          img: require("../../assets/ShopImg/icon_dpj.svg"),
          value: "4",
          lable: "待评价",
        },
        {
          img: require("../../assets/ShopImg/icon_tfk.svg"),
          value: "5",
          lable: "退款/售后",
        },
      ],
      cells: [
        {
          img: require("../../assets/ShopImg/icon_dz.svg"),
          value: "1",
          lable: "地址管理",
          url: "/shop/address/index",
        },
        {
          img: require("../../assets/ShopImg/shopMy.png"),
          value: "2",
          lable: "个人中心",
          url: "/shop/address/index",
        },
        // {
        //   img: require("../../assets/ShopImg/icon_pj.svg"),
        //   value: "2",
        //   lable: "我的评价",
        //   url: "/shop/user/comment",
        // },
        {
          img: require("../../assets/ShopImg/icon_kf.svg"),
          value: "3",
          lable: "在线客服",
          url: "/shop/service/index",
        },
      ],
      user: {},
    };
  },
  created() {
    if (
      this.$route.query.UserId != undefined &&
      this.$route.query.UserId != ""
    ) {
      this.user.Id = this.$route.query.UserId;
      this.getQueryUserInfo();
    }
    var that = this;
    window["callByAndroidParam"] = function (jsonStr) {
      if (jsonStr != "") {
        that.user.Id = jsonStr.data;
        that.getQueryUserInfo();
      } else {
        this.$message.error("登录失败");
      }
    };
  },
  mounted() {
    window.userIDResultByIos = this.userIDResultByIos;
    if (localStorage.getItem("userInfo") != null) {
      this.user = JSON.parse(localStorage.getItem("userInfo"));
      this.getQueryUserInfo();
    } else {
      this.user.Id = 0;
      this.callApp();
    }
  },
  methods: {
    userIDResultByIos(jsonStr) {
      this.$message.error("IOS回调");
      // if (jsonStr != "") {
      //   this.user.Id =JSON.parse(jsonStr).data;
      //   this.getQueryUserInfo();
      // } else {
      //   this.$message.error("登录失败");
      // }
    },
    callApp() {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({ action: "getUserID" }));
      } else if (isiOS) {
        window.webkit.messageHandlers.callIosGetUserID.postMessage(
          JSON.stringify({ action: "callIosGetUserID" })
        );
      }
    },
    toAppMy() {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({ action: "toAppMy" }));
      } else if (isiOS) {
        window.webkit.messageHandlers.toAppMy.postMessage(
          JSON.stringify({ action: "toAppMy" })
        );
      }
    },
    link(obj) {
      if (obj.value == 2) {
        // window.location.href = "https://yxyapp.micmark.com/app/pages/My/index";
        window.location.href =
          "https://live.pulijiaoyu.org.cn/app/pages/My/index";
      } else if (obj.value == 3) {
        window.location.href =
          "https://work.weixin.qq.com/kfid/kfc8db1b9998eef1aba";
      } else {
        this.$router.push({
          path: obj.url,
        });
      }
    },
    toOrder(activeStatus) {
      let path = "";
      if (activeStatus == 5) {
        path = "/shop/orders/afterSales";
      } else {
        path = "/shop/orders/index";
      }
      this.$router.push({
        path: path,
        query: { activeStatus: activeStatus },
      });
    },
    // 用户详情
    async getQueryUserInfo() {
      const res = await queryStudentInfoById(this.user.Id);
      this.user = res.response;
      console.log(this.user);
      localStorage.setItem("userInfo", JSON.stringify(this.user));
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  font-weight: normal;
}
.my {
  .my_top {
    width: 100vw;
    height: 13.5625rem;
    // background-image: url("../../assets/ShopImg/my_bg.png");
    background-color: #efeded;
    background-size: 100%;
    color: #000;
    .title {
      width: 100vw;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      padding-top: 3.4375rem;
      padding-bottom: 1.625rem;
    }
    .user {
      font-size: 17px;
      margin: 0 1rem;
      padding-top: 3rem;
      img {
        width: 4.125rem;
        height: 4.125rem;
        border-radius: 50%;
        margin-right: 0.9375rem;
        margin-top: -30px;
      }
    }
  }
  .my_cont {
    margin-top: -2.5rem;
    background-color: #fff;
    position: relative;
    .my_j_j_d {
      margin: 0 auto;
      display: flex;
      align-items: center;
      width: 90%;
      height: 4.5rem;
      // border-radius: 10px;
      // box-shadow: 3px 3px 7px rgba(71, 71, 71, 0.1);
      // background-color: #fff;
      position: absolute;
      top: -4.875rem;
      left: 0;
      right: 0;
      z-index: 9;
      div {
        width: 33.33%;
        span {
          display: block;
          text-align: center;
          line-height: 1;
          &:nth-child(1) {
            font-size: 24px;
            color: #333333;
            font-weight: bold;
            margin-bottom: 0.9375rem;
          }
          &:nth-child(2) {
            color: #999999;
            font-size: 14px;
          }
        }
      }
    }
    .order {
      padding: 1.5rem 0 1rem;
      .van-cell-group {
        &::after {
          border: none;
        }
        border-bottom: 1px solid #f1efef;
        margin-bottom: 1rem;
      }
      .cell_title {
        font-size: 15px;
        color: #333333;
        font-weight: bold;
      }
      .cell_right {
        color: #999999;
        font-size: 12px;
      }
      .ods {
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        img {
          width: 1.1875rem;
          height: 1.0625rem;
        }
        p {
          margin-top: 0.625rem;
          font-size: 13px;
          line-height: 1;
          color: #333333;
        }
      }
    }
    .cells {
      .cell_title {
        font-size: 15px;
        display: flex;
        align-items: center;
        color: #333333;
        img {
          width: 0.875rem;
          height: 0.875rem;
          margin-right: 0.4375rem;
        }
      }
      .van-cell {
        &::after {
          left: 0;
          right: 0;
        }
      }
    }
  }
}
</style>
